import { useEffect } from 'react'
import Link from 'next/link'
import type { GetServerSideProps } from 'next/types'
import { signIn } from 'next-auth/react'
import type { SSRConfig } from 'next-i18next'
import { Trans, useTranslation } from 'next-i18next'
import nextI18next from 'next-i18next.config'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import CMSLayout from '@cms/components/Layout/CMSLayout'
import { useGlobalContext } from '@cms/hooks/useGlobalContext'
import { useSiteStructureLoader } from '@cms/hooks/useSiteStructureLoader'
import { useUserContent } from '@cms/hooks/useUserContent'
import { mapCacheTagsToContentfulMetadata, mapCmsPageDataToPageProps } from '@cms/pages'
import { CACHE_TAGS_MAX_HEADER_LENGTH } from '@cms/utils/constants'
import type { CMSPageProps } from '@cms/utils/types'
import Page from '@components/Layout/Page'
import { default as i18nConfig } from '@config/i18n'
import { ErrorVisual } from '@knauf-group/ct-designs/components/core/ErrorVisual'
import type { FooterProps } from '@knauf-group/ct-designs/components/core/Footer'
import type { HeaderMainMenu } from '@knauf-group/ct-designs/components/core/WebHeader'
import { WebLink } from '@knauf-group/ct-designs/components/core/WebLink'
import type { IAppsConfig } from '@knauf-group/ct-shared-nextjs/client'
import { getAppConfigProps } from '@knauf-group/ct-shared-nextjs/client'
import logger from '@knauf-group/ct-shared-nextjs/logger'
import { Contentful } from '@knauf-group/ct-shared-nextjs/web/contentful'
import type { PageTypeFields } from '@knauf-group/ct-shared-nextjs/web/contentful/contentful.types'
import { useScrollEvent } from '@knauf-group/ct-shared-nextjs/web/hooks/useWebAnalytics'
import { sharedContentfulProps } from '@knauf-group/ct-shared-nextjs/web/lib/sharedContentfulProps'
import type {
  SiteStructure,
  SiteStructureMapEntry,
} from '@knauf-group/ct-shared-nextjs/web/siteStructure'
import { CONTENT_NESTED_LEVEL } from '@knauf-group/ct-shared-nextjs/web/utils/constants'
import { getSiteStructureWithHash } from '@knauf-group/ct-shared-nextjs/web/utils/sitestructure'
import type { ContentEntry } from '@knauf-group/ct-shared-nextjs/web/utils/types'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'

const CMSPage: React.FC<CMSPageProps> = ({
  locale,
  headerEntries,
  footerEntries,
  cmsPageData,
  siteStructureSerialized,
  breadcrumbLinks,
  optionalPageFeatures,
  isPageInPreviewMode,
  hasUserContent,
  pageId,
  isCommonPage,
  appsConfig,
}) => {
  const {
    content: userContent,
    isError,
    isLoading,
  } = useUserContent({ pageId, isCommonPage, locale, hasUserContent })
  const cmsPageBodyContent = userContent?.data || cmsPageData

  // TODO: first check client-side to see if user claims to have group membership for this page (will double check server-side as well in API route)
  // this can save us an unnecessary API call for good-faith users

  const globalContext = useGlobalContext()
  const { t } = useTranslation('cms', { keyPrefix: 'errors' })

  useSiteStructureLoader(siteStructureSerialized, locale)
  useScrollEvent()

  useEffect(() => {
    globalContext.setOptionalPageFeatures(optionalPageFeatures)
  }, [optionalPageFeatures, globalContext])
  const handleError = (error) => {
    if (error?.cause?.status === 400) {
      signIn('okta')
    } else {
      return (
        <ErrorVisual title={t('pageError403Title')} errorType="404">
          <Typography data-cy="protected-content-access-error">
            <Trans
              i18nKey="errors.pageError403Description"
              components={[
                <WebLink
                  key="mailtoLink"
                  reference={{
                    href: 'mailto:app-support@knauf.com',
                    label: '',
                  }}
                  component="a"
                  nextLinkComponent={Link}
                  data-cy="protected-page-access-denied-link-1"
                />,
                <WebLink
                  key="returnToHomeLink"
                  reference={{ href: '/', label: '' }}
                  component="a"
                  nextLinkComponent={Link}
                  data-cy="protected-page-access-denied-link-2"
                />,
              ]}
            />
          </Typography>
        </ErrorVisual>
      )
    }
  }

  const UserContent: React.FC = () => {
    return (
      <>
        {isLoading && (
          <Box
            sx={{
              height: '100vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {isError ? handleError(isError) : <CMSLayout content={cmsPageBodyContent} />}
      </>
    )
  }

  return (
    <>
      {globalContext.siteStructure && (
        <Page
          headerEntries={headerEntries}
          footerEntries={footerEntries}
          cmsPageFields={cmsPageBodyContent}
          hasUserContent={hasUserContent}
          breadcrumbLinks={breadcrumbLinks}
          isPageInPreviewMode={isPageInPreviewMode}
          locale={locale}
          appsConfig={appsConfig}
        >
          {hasUserContent ? <UserContent /> : <CMSLayout content={cmsPageBodyContent} />}
        </Page>
      )}
    </>
  )
}

export const getServerSideProps: GetServerSideProps<CMSPageProps> = async ({
  locale,
  preview,
  params,
  draftMode = false,
  res,
}) => {
  const { cmsSlug } = params
  const slugFromParams = Array.isArray(cmsSlug) ? cmsSlug.join('/') : ''
  const requestUrl = `/${slugFromParams}`
  let headerEntries: HeaderMainMenu[] | null
  let footerEntries: FooterProps | null
  let siteStructure: SiteStructure | undefined
  let cacheTag: string | null

  // for the scenario where the locale is the default locale, we want to return a 404
  if (locale === i18nConfig.defaultLocale) {
    return {
      notFound: true,
    }
  }

  try {
    ;({ siteStructure, headerEntries, footerEntries, cacheTag } = await sharedContentfulProps({
      locale,
      preview,
      requireSiteStructure: true,
    }))
  } catch (error) {
    logger.error('Error fetching shared contentful props', error)
    return {
      notFound: true,
    }
  }

  const { siteStructureSerialized } = getSiteStructureWithHash(siteStructure) || {}

  let currentPage: SiteStructureMapEntry
  let cmsPageData: ContentEntry<PageTypeFields>
  let translations: SSRConfig
  let appsConfig: {
    appsConfig: IAppsConfig
  }

  try {
    // get page id from site structure map
    currentPage = siteStructure.getPage(requestUrl)
    ;[cmsPageData, translations, appsConfig] = await Promise.all([
      // fetch full page by page id
      Contentful.getPageContentfulEntry<PageTypeFields>({
        entryId: currentPage.pageId,
        locale,
        isPreview: preview,
        isCommonSpace: currentPage.isCommonPage,
        include: CONTENT_NESTED_LEVEL,
      }),
      serverSideTranslations(locale, ['cms'], nextI18next),
      getAppConfigProps(),
    ])

    if (!cmsPageData?.fields) {
      return {
        notFound: true,
      }
    }
  } catch (error) {
    logger.warn(error, { locale })
    return {
      notFound: true,
    }
  }

  const cmsPageDataProps = mapCmsPageDataToPageProps({
    cmsPageData,
    requestUrl,
    siteStructure,
    currentPage,
    draftMode,
    cmsSlug,
  })

  if (!cmsPageDataProps.isPageInPreviewMode) {
    const cacheTags = mapCacheTagsToContentfulMetadata(cmsPageDataProps, cacheTag)

    const concatenatedCacheTags = cacheTags.length > 0 ? `,${cacheTags.toString()}` : ''
    if (concatenatedCacheTags.length <= CACHE_TAGS_MAX_HEADER_LENGTH) {
      res.setHeader('Cache-Tag', `${res.getHeader('Cache-Tag')}${concatenatedCacheTags}`)
    }
  }

  return {
    props: {
      ...cmsPageDataProps,
      locale,
      ...translations,
      ...appsConfig,
      headerEntries,
      footerEntries,
      siteStructureSerialized,
    },
  }
}

export default CMSPage
